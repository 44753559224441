import {twMerge} from "tailwind-merge";

export default function ApartmentIcon({className}) {
    return (
        <svg
            className={twMerge("fill-current text-[1.5rem] block-inline shrink-0", className)}
            viewBox="0 0 24 24"
            aria-hidden="true"
        >
            <path
                d="M17 11V3H7v4H3v14h8v-4h2v4h8V11h-4zM7 19H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm4 4H9v-2h2v2zm0-4H9V9h2v2zm0-4H9V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z">
            </path>
        </svg>
    );
}
