import Checkmark from "../vectors/Checkmark";

export default function SelectOption({ label, isSelected = false, disabled = false }) {
    return (
        <>
            <div className="flex items-center pr-7">
                <span className={`${disabled ? 'text-mild-warm-grey' : ''} block text-base font-${isSelected ? 'semibold' : 'normal'}`} aria-label={`Select option ${label}`}>{label}</span>
            </div>

            {isSelected &&
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-secondary-blue">
                    <Checkmark />
                </span>
            }
        </>
    )
}